
*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html{
  height: 100%;
}

body{
  display: flex;
  min-height: 100%;
}

#root{
  display: flex;
  min-height: 100%;
}

html, body, #root{  
  width: 100%;
  background: #333;
  font-family: 'Roboto Condensed', sans-serif ;  
}

@media (min-width:576px){
  html, body, #root{
    height: 100%;
  }
  .app{
    max-height:100%;
    position: fixed;
  }
}


body{
  overscroll-behavior: none;
}

#root{
  background-image: url('/content/fundo-cinza-4.png');
  background-repeat: repeat;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;  
}

body, input, button{
    font-family: Robot, sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #666; 
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}