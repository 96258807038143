

.app{    
    position: relative;
    width: 100%;    
    display: grid;
    grid-template-rows: '1fr';
    min-height: 100%;
}


.app.with-nav{
    grid-template-rows: 100px 1fr 80px;    
}

@media (min-width:576px){
    .app{
        max-height: 100%;
    }
    .title-header{
        font-size: 20px;
    }
}

.title-header{
    font-size: 12px;
}

.header{
    height: 100px;
    max-width: 100%;
    background-color: white;   
    padding: 2px; 
}

.header div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /* max-width: 1280px; */
    /* margin: 0 auto; */
    padding: 0px;
}

.header h1{
    color: #333;
}

.content{
    color: white;
    /* padding: 0 15px; */
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.container{
    position: relative;
    width: 100%;
    height: 100%;
}


.content h1{
    margin: 15px 0px;
}

.center{
    margin: 0 auto;
    max-width: 1280px;
}

.footer{
    max-width: 100%;
    min-height: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.btn{
    border: 0;
    background: white;
    color: #333;    
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.5s;
    padding: 12px;
    text-decoration: none; /* no underline */
    min-width: 46px;
    min-height: 46px;
    border-radius: 46px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.btn.ativo{
    background: #666 !important;
    color: white !important;
}

.btn:hover{
    background: #bbb;
}

.logo{
    width: 100px;
}


@media (min-width:576px){
    .logo{
      height: 100%;
      width: auto;
    }
    .idiomas{
        margin: 20px;
    }
    .header{
        
    }
  }

.mr{
    margin-right: 12px;
}

h2, h3{
    color: white;
}

.idiomas{
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:5px;
}



.img-idioma{
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0.6;
}

@media (min-width:576px){
    .img-idioma{
      
    }
  }

.img-idioma.idioma-ativo{
    opacity: 1.0;
}

.img-idioma:hover{
    opacity: 1.0;
}
