@media (min-width:576px){
    .ficha-tecnica{
        grid-template-columns: repeat(3, 1fr);
    }
    
}

#container-ficha-tecnica{
    width: 100%;
    height: 100%;
    padding: 40px;
}

.ficha-tecnica{
    height: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);    
    gap:40px;
}

.item-ficha-tecnica{
    height: 100%;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
    color: #333; 
}

.texto-item-ficha-tecnica p{
    margin-top: 10px;
    
}

.img-ficha-tecnica{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-ficha-tecnica img{
    width: 100%;
}

.img-ficha-tecnica.tv-360 img{
    width: 100%;
    max-width: 250px;
}
